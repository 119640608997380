
import { defineComponent } from 'vue';
import MapContainer from './components/MapContainer.vue';

export default defineComponent({
  name: 'App',
  components: {
    MapContainer,
  },
  data() {
    return {
      progressKm: 0,
    };
  },
});
