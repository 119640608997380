
import { defineComponent } from 'vue';
import Map from '@/modules/Map';

export default defineComponent({
  props: {
    progressKm: { type: Number, required: true },
  },
  data() {
    return {
      map: new Map(),
    };
  },
  watch: {
    progressKm() {
      this.map.setProgress(this.progressKm * 1000);
    },
  },
  mounted() {
    this.map.mount(this.$refs.mapcontainer as HTMLElement);
  },
  updated() {
    this.map.mount(this.$refs.mapcontainer as HTMLElement);
  },
});
