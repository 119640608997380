import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import GeometryUtil from 'leaflet-geometryutil';
import 'leaflet-easybutton/src/easy-button.css';
import 'leaflet-easybutton';
import 'leaflet-sidebar/src/L.Control.Sidebar.css';
import 'leaflet-sidebar';
import { decode } from '@mapbox/polyline';

import routedata from '@/assets/route-graphhopper.json';

export default class Map {
  private container = document.createElement('div');
  private map: L.Map;
  private path: L.Polyline;
  private pathProgressed: L.Polyline;
  private pathRemaining: L.Polyline;

  readonly sidebarContainer = document.createElement('div');

  mount(el: HTMLElement): void {
    el.appendChild(this.container);
    this.map.invalidateSize();
    this.map.fitBounds(this.path.getBounds());
  }

  setProgress(metres: number): void {
    const length = GeometryUtil.length(this.path);
    const fraction = metres / length;
    this.pathProgressed.setLatLngs(GeometryUtil.extract(this.map, this.path, 0, fraction));
    this.pathRemaining.setLatLngs(GeometryUtil.extract(this.map, this.path, fraction, 1));
  }

  constructor() {
    this.map = L.map(this.container);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    }).addTo(this.map);

    const points = decode(routedata.paths[0].points);
    this.path = L.polyline(points);
    this.pathProgressed = L.polyline([], { color: 'green' }).addTo(this.map);
    this.pathRemaining = L.polyline([], { color: 'red' }).addTo(this.map);
    this.setProgress(0);

    const sidebar = L.control.sidebar(this.sidebarContainer, { autoPan: false }).addTo(this.map);
    L.easyButton('<span>Click</span>', () => sidebar.toggle()).addTo(this.map);
  }
}
