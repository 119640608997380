<script lang="ts">
import { defineComponent } from 'vue';
import MapContainer from './components/MapContainer.vue';

export default defineComponent({
  name: 'App',
  components: {
    MapContainer,
  },
  data() {
    return {
      progressKm: 0,
    };
  },
});
</script>

<template>
  <MapContainer :progress-km="progressKm">
    <template #sidebar>
      <label>
        Distance travelled (km):
        <input
          v-model.lazy.number="progressKm"
          type="number"
          min="0"
        >
      </label>
    </template>
  </MapContainer>
</template>

<style lang="scss">
body {
  margin: 0;
}
#app {
  height: 100vh;
}
</style>
