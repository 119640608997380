<script lang="ts">
import { defineComponent } from 'vue';
import Map from '@/modules/Map';

export default defineComponent({
  props: {
    progressKm: { type: Number, required: true },
  },
  data() {
    return {
      map: new Map(),
    };
  },
  watch: {
    progressKm() {
      this.map.setProgress(this.progressKm * 1000);
    },
  },
  mounted() {
    this.map.mount(this.$refs.mapcontainer as HTMLElement);
  },
  updated() {
    this.map.mount(this.$refs.mapcontainer as HTMLElement);
  },
});
</script>

<template>
  <div
    ref="mapcontainer"
    class="mapcontainer"
  />
  <teleport :to="map.sidebarContainer">
    <slot name="sidebar" />
  </teleport>
</template>

<style lang="scss">
.mapcontainer, .leaflet-container {
  height: 100%;
}
.leaflet-sidebar.left .close {
  margin-right: -10px; /* compensate for container's lack of padding on the right */
}
</style>
